import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93')
];

export const server_loads = [0,10];

export const dictionary = {
		"/": [14],
		"/(app)/@[slug]": [40,[2]],
		"/(app)/assets/g/lists/og1/[id]": [41,[6,7]],
		"/(app)/assets/g/trust-score/og1/[id]": [42,[6,8]],
		"/(app)/assets/g/u/[slug]/stack/og1": [43,[6,9]],
		"/(app)/brands": [44,[2]],
		"/(app)/brands/[slug]": [45,[2]],
		"/(app)/brands/[slug]/trustscore": [46,[2]],
		"/contact": [74],
		"/delete-account": [75],
		"/(app)/discover": [47,[2]],
		"/(app)/download": [48,[2]],
		"/(app)/(private)/feedback": [15,[2,3]],
		"/get-started": [76],
		"/get-started/find-trusted-products": [77],
		"/get-started/get-recommendations": [78],
		"/get-started/get-reminders": [79],
		"/get-started/optimize-my-stack": [80],
		"/get-started/save-money": [81],
		"/(app)/(private)/home": [16,[2,3]],
		"/(app)/(private)/internal/chat": [17,[2,3]],
		"/(app)/lists/[id]": [49,[2]],
		"/login": [~82],
		"/logout": [~83],
		"/lp/[slug]": [84],
		"/(app)/(private)/mocks/auth/signin": [18,[4]],
		"/(app)/(private)/mocks/auth/waitlist": [19,[4]],
		"/(app)/(private)/my/analyses/suppscore": [20,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/complete": [21,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/wizard": [22,[2,3]],
		"/(app)/(private)/my/analyses/suppscore/wizard/[slide]": [23,[2,3]],
		"/(app)/(private)/my/cabinet": [24,[2,3]],
		"/(app)/(private)/my/lists": [25,[2,3]],
		"/(app)/(private)/my/lists/[id]": [26,[2,3]],
		"/(app)/(private)/my/lists/[id]/edit": [27,[2,3]],
		"/(app)/(private)/my/protocols": [28,[2,3]],
		"/(app)/(private)/my/schedule/[[date]]": [29,[2,3]],
		"/(app)/(private)/my/stack/nutrients": [30,[2,3,5]],
		"/(app)/(private)/my/stack/products": [31,[2,3,5]],
		"/(app)/(private)/my/stack/protocols": [32,[2,3,5]],
		"/(app)/nutrients": [50,[2]],
		"/(app)/nutrients/popular": [52,[2]],
		"/(app)/nutrients/[slug]": [51,[2]],
		"/(app)/products": [53,[2]],
		"/(app)/(private)/products/edit": [33,[2,3]],
		"/(app)/products/popular": [55,[2]],
		"/(app)/products/search/nutrients": [56,[2,10]],
		"/(app)/products/search/nutrients/[slug]": [57,[2,10]],
		"/(app)/(private)/products/submit/[[id]]": [34,[2,3]],
		"/(app)/products/[slug]": [54,[2]],
		"/(app)/profiles/popular": [60,[2]],
		"/(app)/profiles/[slug]": [58,[2]],
		"/(app)/profiles/[slug]/collections/quick-details": [59,[2]],
		"/(app)/(private)/profile": [35,[2,3]],
		"/(app)/(private)/profile/logs": [~36,[2,3]],
		"/(app)/protocols": [61,[2]],
		"/(app)/protocols/[slug]": [62,[2]],
		"/(app)/protocols/[slug]/match-confirm": [63,[2]],
		"/(app)/protocols/[slug]/select-products": [64,[2]],
		"/(app)/(private)/saves": [37,[2,3]],
		"/(app)/scanner": [65,[2]],
		"/sentry/example": [85],
		"/(app)/(private)/settings": [38,[2,3]],
		"/(app)/(private)/settings/profile": [39,[2,3]],
		"/signup": [~86],
		"/(app)/stacks/users/[slug]": [66,[2,11]],
		"/(app)/stacks/users/[slug]/nutrients": [67,[2,11]],
		"/(app)/stacks/users/[slug]/products": [68,[2,11]],
		"/(app)/stacks/users/[slug]/protocols": [69,[2,11]],
		"/(app)/supplements": [70,[2]],
		"/(app)/supplements/popular": [72,[2]],
		"/(app)/supplements/[slug]": [71,[2]],
		"/(app)/trustscore": [73,[2]],
		"/waitlist": [87,[12]],
		"/waitlist/[waitlistId]": [88,[12,13]],
		"/waitlist/[waitlistId]/accepted": [89,[12,13]],
		"/waitlist/[waitlistId]/access_code": [90,[12,13]],
		"/waitlist/[waitlistId]/edit": [91,[12,13]],
		"/waitlist/[waitlistId]/registered": [92,[12,13]],
		"/waitlist/[waitlistId]/waiting": [93,[12,13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';